import React, { useState,useRef, useEffect } from 'react';
import logo from '../images/logoW192.png';
import glds from '../images/GLDSLogoFinal-White-All2.png';


import { createFormDataObj,apiDataFetch } from '../Utilities';

import FormPopoverPortal from '../portals/FormPopoverPortal';
import WEButton from '../components/WEButton/WEButton';
import WEInput from '../components/WEInput/WEInput';

import styles from './signupForm.module.css';
import WECheckbox from '../components/WECheckbox/WECheckbox';

const LogoRow = ({ mouseUp,mouseDown }) => {

    
    return (
        <div className={styles.logo_row}>
            <div className={styles.m_logo_wrapper}>
                <img onMouseDown={mouseDown} onMouseUp={mouseUp} onTouchStart={mouseUp} onTouchEnd={mouseDown} src={logo} style={{opacity:.75,filter: 'drop-shadow(-2px -2px 4px black'}} height={165} width={115} alt="pocket reports logo" />
            </div>
        </div>
    )
}

const FormRow = ({ children }) => {
    return (
        <div className={styles.form_row}>
            <form>
                {children}
            </form>
        </div>
    )
}

const FormModal = () => {
   
    return (
        <div className={styles.form_modal}>
            <div className={styles.modal_wrapper}>
                <div className={styles.confirm_modal}>
                    <div className={styles.cmp_logo}><img src={glds} width={140} height={75} /></div>
                    <h1>Your request has been sent!</h1>
                    <p>Thank you for your interest in Pocket Reports. Someone from GLDS will reach out to you shortly to sign a contract and discuss payment details.</p>
                </div>                                
            </div>
        </div>
    )
}


const useTextField = () => {
    const [value,setValue] = useState("");
    const [status,setStatus] = useState("idle");

    const onChangeEvent = (e) => {
        setValue(e.target.value);
    }

    const onStatusChangeEvent = (_status="idle") => {
        setStatus(_status);
    }

    return [value,status,onChangeEvent,onStatusChangeEvent]
}


function SignupForm(props) {
    const [confirmModalShowing,setConfirmModalShowing] = useState(false);
    const [showPaymentCB,setShowPaymentCB] = useState(false);
    const [coords,setCoords] = useState({});
    const [nameValue,nameStatus,onNameChange,onNameStatusChange] = useTextField();
    const [emailValue,emailStatus,onEmailChange,onEmailStatusChange] = useTextField();
    const [phoneValue,phoneStatus,onPhoneChange,onPhoneStatusChange] = useTextField();
    const [storeNameValue,storeNameStatus,onStoreNameChange,onStoreNameStatusChange] = useTextField();
    const [cityValue,cityStatus,onCityChange,onCityStatusChange] = useTextField();
    const [payCollected,setPayCollected] = useState(false);

    const isMouseDownRef = useRef();

    const onMouseDownEvent = (e) => {
        console.log("Mouse Down");
        isMouseDownRef.current = setTimeout(() => {
            let left = 0;
            let top = 0;
            setShowPaymentCB(!showPaymentCB);
            clearTimeout(isMouseDownRef.current);
        },500);
    }
    const onMouseUpEvent = (e) => {
        clearTimeout(isMouseDownRef.current);
    }

    const modalCloseEvent = (e) => {

        console.log("Button close event");
        setConfirmModalShowing(false);
    }
   
    const validateInputValue = (_value,_name) => {
        
        if (_value === ""){
            switch (_name) {
                case "name":
                    onNameStatusChange("error");
                    break;
                case "email":
                    onEmailStatusChange("error");
                    break;
                case "phone":
                    onPhoneStatusChange("error");
                    break;
                case "store name":
                    onStoreNameStatusChange("error");
                    break;
                case "city":
                    onCityStatusChange("error");
                    break;            
                default:
                    break;
            }
        }
    }

    const submitButtonClickEvent = (e) => {
        e.preventDefault();

        validateInputValue(nameValue,'name');
        validateInputValue(emailValue,'email');
        validateInputValue(phoneValue,'phone');
        validateInputValue(storeNameValue,'store name');
        validateInputValue(cityValue,'city');

        let formData = {
            name: nameValue,
            email: emailValue,
            phone: phoneValue,
            storeName: storeNameValue,
            city: cityValue,
            paid: payCollected ? 0 : 1
        }

        const fd = createFormDataObj(formData);
        
        apiDataFetch("https://signup.gldstools.com/register.php",{method:"POST",body:fd}).then(result => {
            console.log('Organization detail view');
            console.log(result);
            debugger;
            if (result.success === false){
                alert("Invalid email address provided");
                onEmailChange("");
                return;    
            }
            setConfirmModalShowing(true);
            onNameChange({target:{value:""}});
            onEmailChange({target:{value:""}});
            onPhoneChange({target:{value:""}});
            onStoreNameChange({target:{value:""}});
            onCityChange({target:{value:""}});

        }).catch(error => {
            console.error('Error:',error);
        })
        
    }

    return (
        <div className={styles.signup_form}>
            <LogoRow mouseDown={onMouseDownEvent} mouseUp={onMouseUpEvent}/>
            <FormRow>
                <WEInput
                    id="c_name"
                    name="name"
                    value={nameValue}
                    onChange={e => onNameChange(e)}
                    status={nameStatus}
                    autoComplete="off"
                    required={true}
                />
                <WEInput
                    id="c_email"
                    name="email"
                    value={emailValue}
                    onChange={e => onEmailChange(e)}
                    status={emailStatus}
                    autoComplete="off"
                    required={true}
                />
                <WEInput
                    id="c_phone"
                    name="phone"
                    value={phoneValue}
                    onChange={e => onPhoneChange(e)}
                    status={phoneStatus}
                    autoComplete="off"
                    required={true}
                />
                <WEInput
                    id="c_store_name"
                    name="store name"
                    value={storeNameValue}
                    onChange={e => onStoreNameChange(e)}
                    status={storeNameStatus}
                    autoComplete="off"
                    required={true}
                />
                <WEInput
                    id="c_city"
                    name="city"
                    value={cityValue}
                    onChange={e => onCityChange(e)}
                    status={cityStatus}
                    autoComplete="off"
                    required={true}
                />
                {showPaymentCB && 
                    <div className={styles.checkbox_row}>
                        <span>Payment Collected</span>
                        <WECheckbox isChecked={payCollected} action={e => setPayCollected(!payCollected)} />
                    </div>
                }
                <WEButton btnColor='#00a651' onClick={submitButtonClickEvent} >
                        Submit
                </WEButton>
                
            </FormRow>
            {confirmModalShowing && (
                <FormPopoverPortal>
                    <FormModal coords={coords}  onClose={modalCloseEvent}/>
                </FormPopoverPortal>
            )}
        </div>
    );
}

export default SignupForm;