import { useEffect } from 'react';
import { createPortal } from 'react-dom';


const FormPopoverPortal = ({ children }) => {
    const mount = document.getElementById('pay_modal_portal_root');
    const el = document.createElement('div');

    useEffect(() => {
        mount.appendChild(el);
        return () => mount.removeChild(el);
    },[el,mount])
    return createPortal(children,el);
}

export default FormPopoverPortal;