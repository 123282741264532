import './App.css';
import SignupForm from './SignupForm/SignupForm';

function App() {
  return (
    <div className="App">
        <SignupForm />
    </div>
  );
}

export default App;
