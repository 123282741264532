import React from 'react';

import styles from './weCheckbox.module.css';

const Container = ({ children }) => {
    return (
        <div className={`${styles.container}`}>
            {children}
        </div>
    )
}

const Input = ({ isChecked,action }) => {
    return (
        <label className={styles.input_wrapper} htmlFor={'we-checkbox-input'} title="Add license">
            <input
                type="checkbox"
                tabIndex="0"
                id={"we-checkbox-input"}
                checked={isChecked}
                onChange={action}                
            />
            <span className={styles.checkmark}></span> 
        </label>
    )
}


function WECheckbox({ isChecked,action }) {
    return (
        <Container>
            <Input isChecked={isChecked} action={action} />
        </Container>
    );
}

export default WECheckbox;