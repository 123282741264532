import React, { useRef,useEffect,useState } from 'react';
import { icons } from './assets/svgIcons';


import styles from './weInput.module.css';


const transitionTimer = (_element,_className,duration = 1) => {
    _element.classList.toggle(_className);
    let intV = setTimeout(() => {
        _element.classList.toggle(_className);
        clearTimeout(intV);
    },duration);
}


// Input parent container component
const Input = ({ status,theme="",children }) => {

    const containerRef = useRef(null);

    useEffect(() => {
        
        switch (status) {
            case 'error':
                transitionTimer(containerRef.current,styles.is_error, 5000);
                break;
        
            default:
                break;
        }

    },[status]);

    return (
        <div ref={containerRef} className={`${styles.container} ${styles[theme]}`} id="we-fl__input-div">
            {children}
        </div>
    )
}

// Input status component
const Status = () => {
    return (
        <div className={styles.status}>
            {icons(25,"#FFCF56").loading}            
        </div>
    )
}
Input.Status = Status;

// Input Clear button component
const ClearButton = ({ action }) => {
    return (
        <button className={styles.close_btn} onClick={action}>
            {icons(25,"#FFCF56").clearX}
        </button>
    )
}
Input.ClearButton = ClearButton;

// Input text field component
const TextField = ({ value,onChange,...props }) => {  
    return (
        <input                   
            id={`we-fl__field-input-${props.id}`}
            className={`${styles.text_field}`}
            value={value}
            onChange={onChange}
            {...props}
            disabled={props.disabled}
            placeholder={props.placeholder ? props.placeholder : `Enter ${props.name}`}
            data-error="test"
            autoComplete={"off"}
        />
    )
}
Input.TextField = TextField;

// Input label component
const Label = ({ children,...props }) => {
    return (
        <label
            htmlFor={`we-fl__field-input-${props.id}`}
            aria-label={`${props?.name ? props.name : "a"} text input`}
        >
            {children}
        </label>
    )
}
Input.Label = Label;





/**
 * 
 * @param {*} param0 
 * @returns 
 */
const WEInput = ({ value,onChange,status = 'idle',theme = '',validator=null,...props }) => {
    const [inputValue,setInputValue] = useState(value);
    const [inputStatus,setInputStatus] = useState(status);
    
    const onChangeEvent = (e) => {
        e.preventDefault();
        setInputStatus('idle');
        if (validator){
            validator(e.target.value);
            setInputStatus('error');            
        }
        setInputValue(e.target.value);
        onChange(e);
    }

    const  onClearInputClickEvent = (e) => {
        setInputValue("");
        let target = e.target;
        let tmp = target.closest("button").nextElementSibling;
        if (tmp.localName !== "input") {
            return;
        }
        e.target.value = "";
        onChange(e);            
    }

   
    return (
        <Input status={status}>
            {(status === "busy") && <Input.Status />}
            {/* {(status === "idle" && value !== "")  && <Input.ClearButton action={onClearInputClickEvent} />} */}
            <Input.TextField value={inputValue} onChange={onChangeEvent} {...props} disabled={status === 'busy' ? true : false} />
            <Input.Label>
                {props.name}
            </Input.Label>
        </Input>
    )
};

export default WEInput;